'use client'
import ChatError from '@/app/[replicaId]/ChatError'
import useChatReplicaError from '@/app/[replicaId]/useChatReplicaError'
import Spinner from '@/components/Spinner'
import useWhisperTranscription from '@/hooks/useWhisperTranscription'
import StreamingAvatar, { AvatarQuality, type StartAvatarResponse, StreamingEvents } from '@heygen/streaming-avatar'
import { ArrowRight, CircleNotch, Microphone, Play, Stop, X } from '@phosphor-icons/react'
import { usePrevious } from 'ahooks'
import {
  type FormEvent,
  type KeyboardEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

const VideoStreaming = forwardRef(function VideoStreaming(
  {
    className,
    showCloseButton = true,
    silenceDuration = 1500,
  }: { className?: string; showCloseButton?: boolean; silenceDuration?: number },
  ref,
) {
  const { error, resetError, setError } = useChatReplicaError()
  const avatar = useRef<StreamingAvatar | null>(null)
  const mediaStream = useRef<HTMLVideoElement>(null)

  const avatarId = '36174163f8f44f70b21afc5ac6c10dda'
  const [muted, setMuted] = useState<boolean>(true)
  const [isLoadingSession, setIsLoadingSession] = useState<boolean>(false)
  const [stream, setStream] = useState<MediaStream>()
  const [data, setData] = useState<StartAvatarResponse>()
  const [input, setInput] = useState<string>('')
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const { isListening, formatDuration, duration, isProcessing, toggleRecording } = useWhisperTranscription(
    (input: string) => {
      repeatHandler(input)
    },
    isSpeaking,
    silenceDuration,
  )

  useImperativeHandle(ref, () => ({
    streamHandler,
  }))

  function streamHandler() {
    if (stream) endSession()
    else startSession()
  }

  async function fetchAccessToken() {
    try {
      const response = await fetch('/api/heygen/access-token', {
        method: 'POST',
      })
      const res = await response.json()

      return res.token
    } catch (error) {
      setError({
        error,
        friendlyError: 'Something went fetching the token.',
      })
    }
  }

  async function startSession() {
    setIsLoadingSession(true)

    const newToken = await fetchAccessToken()
    avatar.current = new StreamingAvatar({
      token: newToken,
    })

    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      console.log('Avatar started talking', e)
      setIsSpeaking(true)
    })

    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log('Avatar stopped talking', e)
      setIsSpeaking(false)
    })

    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log('Stream disconnected')
      endSession()
    })

    try {
      const res = await avatar.current.createStartAvatar({
        quality: AvatarQuality.High,
        avatarName: avatarId,
      })

      setData(res)
      avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
        console.log('Stream ready:', event.detail)
        setStream(event.detail)
        setIsLoadingSession(false)
      })
    } catch (error) {
      setError({
        error,
        friendlyError: 'There was an error starting the session.',
      })
      setStream(undefined)
      setIsLoadingSession(false)
    }
  }

  const endSession = useCallback(async () => {
    if (!data) return

    if (!avatar.current) {
      setError({
        error: 'Avatar API not initialized',
        friendlyError: 'Avatar API not initialized',
      })
      return
    }

    await avatar.current.stopAvatar({
      sessionId: data.session_id,
    })
    setStream(undefined)
  }, [data])

  const speak = useCallback(
    async (input: string) => {
      if (!data || !avatar.current) return

      setIsSpeaking(true)

      await avatar.current.speak({ text: input, sessionId: data.session_id }).catch(console.log)
    },
    [data],
  )

  const repeatHandler = useCallback(
    async (input: string) => {
      if (!stream) return
      if (!avatar.current) {
        setError({
          error: ' Avatar API is not initialized',
          friendlyError: 'Avatar API is not initialized',
        })
        return
      }

      if (!data) {
        setError({
          error: 'No session data',
          friendlyError: 'No session data',
        })
        return
      }

      try {
        await speak(input)
      } catch (error) {
        setError({
          error,
          friendlyError: 'An unknown error occurred.',
        })

        return
      }
    },
    [data, stream, speak],
  )

  const previousText = usePrevious(input)
  useEffect(() => {
    if (!data) return

    if (!previousText && input) {
      avatar.current?.startListening({ sessionId: data.session_id })
    } else if (previousText && !input) {
      avatar?.current?.stopListening({ sessionId: data.session_id })
    }
  }, [input, previousText, data])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (error) {
      resetError()
    }

    repeatHandler(input)
    setInput('')
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSubmit(e)
    }
  }

  useEffect(() => {
    return () => {
      endSession()
    }
  }, [endSession])

  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream

      const handlePlay = () => setIsPlaying(true)
      const handlePause = () => setIsPlaying(false)

      mediaStream.current.addEventListener('play', handlePlay)
      mediaStream.current.addEventListener('pause', handlePause)

      // Cleanup function to remove event listeners
      return () => {
        if (mediaStream.current) {
          mediaStream.current.removeEventListener('play', handlePlay)
          mediaStream.current.removeEventListener('pause', handlePause)
          mediaStream.current.onloadedmetadata = null
        }
      }
    }
  }, [stream])

  return (
    (isLoadingSession || stream) && (
      <div
        className={twMerge(
          'group absolute inset-x-0 bottom-8 h-[420px] w-[98%] mx-auto overflow-hidden rounded-2xl bg-background-2 md:h-[500px] md:w-full md:bottom-10 z-10',
          className,
        )}
      >
        {!isPlaying && stream && (
          <div
            onClick={() => {
              mediaStream.current?.play()
              textareaRef.current?.focus()
              setMuted(false)
            }}
            className="group cursor-pointer absolute inset-0 bg-black/50 z-10 text-white grid place-content-center"
          >
            <span>
              <Play className="group-hover:scale-110 transition-all" size={30} />
            </span>
          </div>
        )}

        {stream && showCloseButton && (
          <button
            className="absolute right-2 top-2 z-10 rounded-full bg-white/30 p-1 text-sm text-black opacity-30 transition-all duration-300 group-hover:opacity-100"
            onClick={endSession}
          >
            <X size={20} />
          </button>
        )}

        <div className="absolute left-0 right-0 top-0 bg-background z-20">
          <ChatError />
        </div>

        {stream && (
          <video
            ref={mediaStream}
            autoPlay={false}
            muted={muted}
            playsInline
            width={240}
            height={320}
            style={{
              objectFit: 'cover',
            }}
            className="h-full w-full object-cover"
          />
        )}

        {stream && (
          <div className="absolute inset-x-0 bottom-0 pb-4 pt-2 bg-transparent backdrop-blur">
            <form onSubmit={onSubmit} onKeyDown={handleKeyDown}>
              <fieldset className="rounded-full flex items-center w-[95%] mx-auto border border-[#dfd9c8]/50 bg-[#ece6d6]">
                <textarea
                  ref={textareaRef}
                  autoFocus
                  className={twMerge(
                    'px-3 h-auto w-full resize-none overflow-y-auto border-0 bg-transparent text-black placeholder:text-[#969285] focus-within:outline-none focus:ring-0',
                    !stream && 'opacity-40',
                  )}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Type something..."
                  rows={1}
                  disabled={!stream}
                />

                <button
                  className={twMerge(
                    'flex items-center justify-center w-12 border-l border-[#dfd9c8]/90 py-2.5',
                    !stream && 'opacity-40',
                    isListening && 'w-28',
                  )}
                  onClick={toggleRecording}
                  type="button"
                  disabled={isProcessing || !stream}
                >
                  {!isProcessing &&
                    (isListening ? (
                      <Stop className="flex-shrink-0 h-5 w-5 text-red-500" />
                    ) : (
                      <Microphone className="h-5 w-5 text-gray-500" />
                    ))}

                  {isProcessing && <CircleNotch className="h-5 w-5 text-gray-500 animate-spin" />}

                  {isListening && <span className="ml-2 text-sm text-red-500"> {formatDuration(duration)}</span>}
                </button>

                <button
                  className="flex h-[42px] w-[42px] shrink-0 items-center justify-center rounded-full bg-primary text-white shadow-md transition-all duration-300 hover:opacity-70"
                  type="submit"
                >
                  <ArrowRight weight="bold" width={20} height={20} className="fill-current" />
                </button>
              </fieldset>
            </form>

            <div className="flex items-center w-[90%] mx-auto text-xs text-background">
              Press <Microphone /> and leave it open to enable speech-to-speech.
            </div>
          </div>
        )}

        {!stream && (
          <div className="flex h-full w-full items-center justify-center text-white">
            <Spinner size={40} />
          </div>
        )}
      </div>
    )
  )
})

export default VideoStreaming
